<template>
  <div class="d-flex justify-content-center">

    <loading loader="spinner"
             :active.sync="isLoading"
             :can-cancel="true"
             :is-full-page="true"></loading>

    <b-form @submit="onSubmit" class="input-form" >

      <br/>

      <inputSelect :edit="true"
                   v-bind:label="'Lists'"
                   v-bind:var_name="'selectedList'"
                   v-bind:var_value="selectedList"
                   v-bind:optional="true"
                   v-bind:list="namesOfLists"
                   class="name-list"
                   @onChangedValue="onSelectList"
      />

      <div v-if="selectedList">
          <br/>

          <div v-for="(item, index) in filtered" :key="item.code">

            <inputTextform :edit="true"
                           v-bind:label="''"
                           v-bind:var_name="'value_' + index"
                           v-bind:var_value="form.values[index]"
                           v-bind:optional="false"
                           @onChangedValue="onChangedValue"
            />
            <br/>
          </div>

        <table class="table-buttons">
          <tr>
            <td style="text-align-last: end;">

              <b-icon-plus-circle variant="success" class="h2 mb-2"
                                      title="Add a new item"
                                      @click="form.values.push('')"></b-icon-plus-circle></td>
            <td>
              <b-button type="submit" variant="primary">SAVE</b-button>
            </td>
          </tr>
        </table>




      </div>
    </b-form>

  </div>
</template>

<style scoped>

.input-form {
  width: 400px;
  font-size: small;
}

.table-buttons {
  width: 100%;
}
.name-list {
  font-size: small;
}
</style>
<script>

import { mapState, mapGetters, mapActions } from 'vuex'
import inputTextform from '@/views/components/forms/Form.input.text'
import inputSelect from '@/views/components/forms/Form.input.select'

import VueLoading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'Lists Home',
  components: {
    inputTextform,
    inputSelect,
    loading: VueLoading
  },
  data() {
    return {
      isLoading: true,
      selectedList: null,
      form: {
        values:  []
      }
    }
  },
  computed: {
    ...mapState(['list_of_lists', 'list', 'user']),
    ...mapGetters(['list_of_lists', 'list', 'user']),
    namesOfLists(){
      return this.list_of_lists.map(x => x.name).sort();
    },
    filteredObject(){
      return this.list_of_lists.filter(x => x.name==this.selectedList)[0];
    },
    filtered(){
      const values = this.list_of_lists.filter(x => x.name==this.selectedList)[0].values.sort();
      this.setFormValues(values);
      return values;
    }
  },
  methods: {
    ...mapActions(['getLists','getUser']),
    onSelectList(v){
      this.selectedList = v.value;
    },
    onChangedValue(v){
      const index = v.name.split('_')[1];
      this.form.values[index] = v.value;

    },
    onSubmit(event) {
      event.preventDefault()
      this.form.name = this.selectedList;
      const payload = { form: this.form, previous: this.filteredObject, user: this.user.user, reason: ''}
      this.$store.dispatch('saveList', payload);

    },
    setFormValues(v){
      this.form.values = v;
    }
    /*,
    changeUser(){
      this.$store.dispatch('changeUser',{name : 'manolo'} )
      //localStorage.setItem(LOCAL_STORAGE, this.$store.state.user.name)
    }
    */
  },
  mounted() {
    this.getLists().then(() => { this.isLoading=false; })
    this.getUser()
    //console.log(localStorage.getItem(LOCAL_STORAGE)) /* in order to retrieve parameters of the view in this session */
  }
}



</script>

