<template>
  <b-container >
    <b-row>
      <b-col lg="5"><label class="text-sm-left" >{{label}}:</label></b-col>
      <b-col><b-form-input
          :id="'input-' + var_name"
          v-model="in_value"
          :disabled="!editable"
          :state="validation_entry"
          @change="onChangedValue"
      ></b-form-input></b-col>
      <b-col v-if="showButton">
        <b-button v-on:click="onEnter" variant="primary">{{label_enter}}</b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import utils from '@/utils'

export default {
  props: {
    edit: Boolean,
    optional: Boolean,
    label: String,
    label_enter: String,
    var_name: String,
    var_value: String,
    event_button: Boolean
  },
  event: 'onEnterValue',
  data() {
    return {
      editable: this.edit || false,
      in_value: this.var_value,
      name: this.var_name || '',
      enable_button: this.event_button || false
    }
  },
  computed: {
    validation_entry() {
      return this.optional || !utils.isEmpty(this.in_value)
    },
    showButton(){
      return this.enable_button;
    }
  },
  methods: {
    onChangedValue () {
      this.$emit('onChangedValue', { name: this.var_name, value: this.in_value} )
    },
    onEnter(){
      this.$emit('onEnterValue', { name: this.var_name, value: this.in_value} )
    }
  },
  watch: {
    edit: function (newVal) { this.editable = newVal },
    var_value: function (newVal) { this.in_value = newVal }
  },
}
</script>
